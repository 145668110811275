<template>
    <div>
        <Banner></Banner>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>产品优势</p>
                    <p>PRODUCT ADVANTAGE</p>
                </div>
                <div class="hxjs-body">
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/sdk/adv_gzzd.png">
                        <p class="adv_title"><span>适用各种终端</span></p>
                        <p class="adv_det"><span>支持Android的各种终端设备，如手机、平板、翻译机等。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/sdk/adv_yzdy.png">
                        <p class="adv_title"><span>语种多样</span></p>
                        <p class="adv_det"><span>支持中英日韩等多种语种的识别，翻译，合成。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/sdk/adv_mxxq.png">
                        <p class="adv_title"><span>模型小巧</span></p>
                        <p class="adv_det"><span>识别、翻译、合成模型小巧，便于不同性能的终端设备的安装。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/sdk/adv_xyxs.png">
                        <p class="adv_title"><span>响应迅速</span></p>
                        <p class="adv_det"><span>不受环境和网络的影响，在任何场景下都可以提供一流稳定的服务。</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container-grey">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>产品功能</p>
                    <p>PRODUCT FUNCTION</p>
                </div>
                <div class="hy_cpgn_body">
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/sdk/fun_ssfy.jpg">
                        <div>
                            <p class="fun_title"><span>实时翻译</span></p>
                            <p class="fun_det"><span>采用先进的端到端神经网络翻译技术，为您提供高效，精准的翻译。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/sdk/fun_tscy.jpg">
                        <div>
                            <p class="fun_title"><span>同声传译</span></p>
                            <p class="fun_det"><span>同步翻译说话内容，支持中英日韩等多种语言，解决跨国沟通的问题。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/sdk/fun_fyzx.jpg">
                        <div>
                            <p class="fun_title"><span>翻译转写</span></p>
                            <p class="fun_det"><span>支持文本音频的翻译转写，自定义热词，术语等，提升准确率。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/sdk/fun_dhsb.jpg">
                        <div>
                            <p class="fun_title"><span>对话识别</span></p>
                            <p class="fun_det"><span>准确的识别说话内容，满足直播，视频会议等多样的业务场景。</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hy_index_bg">
            <div class="hy_index_cpyss content-container">
                <div class="hy_index_title page-title">
                    <p>产品演示</p>
                    <p>PRODUCT DEMONSTRATION</p>
                </div>
                <div class="hy_cpys_body hy_cpys_sdk_body">
                    <div class="video_box">
                        <div @click="playVideo('video1')">
                            <video
                                ref="video1"
                                src="~@/assets/imgs/sdk/sdk1.mp4"
                                poster="~@/assets/imgs/sdk/sdk_asr.jpg"
                                controls
                                preload>
                            </video>
<!--                            <img v-if="playVideo1" src="~@/assets/imgs/sdk/sdk_play.png" alt="">-->
                        </div>
                        <div @click="playVideo('video2')">
                            <video
                                ref="video2"
                                src="~@/assets/imgs/sdk/sdk2.mp4"
                                poster="~@/assets/imgs/sdk/sdk_tts.jpg"
                                controls
                                preload>
                            </video>
<!--                            <img v-if="playVideo2" src="~@/assets/imgs/sdk/sdk_play.png" alt="">-->
                        </div>
                        <div @click="playVideo('video3')">
                            <video
                                ref="video3"
                                src="~@/assets/imgs/sdk/sdk3.mp4"
                                poster="~@/assets/imgs/sdk/sdk_nmt.jpg"
                                controls
                                preload>
                            </video>
<!--                            <img v-if="playVideo3" src="~@/assets/imgs/sdk/sdk_play.png" alt="">-->
                        </div>
                    </div>
                    <div class="video_pic_box">
                        <div class="switchVideo video_sdk_1" @click="playVideo('video1')">
                            <p><span>识别</span></p>
                        </div>
                        <div class="switchVideo video_sdk_2" @click="playVideo('video2')">
                            <p><span>合成</span></p>
                        </div>
                        <div class="switchVideo video_sdk_3" @click="playVideo('video3')">
                            <p><span>翻译</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>项目案例</p>
                    <p>PROJECT CASES</p>
                </div>
                <div class="hy_xmal_body">
                    <div class="swiper-container swiper" id="swiper2">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide s-slide-1">
                                <div class="case_name">
                                    <p><span>录音笔厂商</span></p>
                                    <p><span>一家录音笔厂商，产品支持全球化的用户需求</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是一家录音笔厂商，产品支持全球化的用户需求。针对该公司的特点，慧言科技为该公司提供了日语离线SDK，满足使用过程中的音频文件转写，翻译等场景，并支持将转写结果、音频文件分享等多种操作，丰富了产品特性。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-2">
                                <div class="case_name">
                                    <p><span>翻译设备公司</span></p>
                                    <p><span>慧言科技为某生产翻译设备的公司，提供了离线SDK部署方案</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>慧言科技为某生产翻译设备的公司，结合公司的销售特点，提供离线SDK部署方案，解决了设备在网络不佳或没有网络的环境下，使用效果不佳的问题，提升了品牌形象。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-3">
                                <div class="case_name">
                                    <p><span>物联网企业</span></p>
                                    <p><span>某公司是一家大型家居企业，专注于打造智能家居产品</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是一家大型家居企业，专注于打造智能家居产品。针对该公司的特点，慧言科技为其提供了一句话识别语音解决方案，并支持命令词的定制，实现了设备的智能开启。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-4">
                                <div class="case_name">
                                    <p><span>医疗机构</span></p>
                                    <p><span>慧言科技为某地医院，部署了离线语音识别解决方案</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>慧言科技为某地医院，部署了离线语音识别解决方案，在满足了数据的安全性要求的前提下，支持对问诊过程的自动记录，并形成电子病历，降低了医疗纠纷，提高了医生的工作效率。</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev" id="swiper_prev2"></div>
                    <div class="swiper-button-next" id="swiper_next2"></div>
                </div>
            </div>
        </div>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>合作伙伴</p>
                    <p>COOPERATIVE PARTNER</p>
                </div>
                <div class="hzhb-body">
                    <div><img src="~@/assets/imgs/par_11.jpg" alt=""></div> <!--滴滴-->
                    <div><img src="~@/assets/imgs/par_12.jpg" alt=""></div> <!--阿里巴巴-->
                    <div><img src="~@/assets/imgs/par_13.jpg" alt=""></div> <!--阿里云-->
                    <div><img src="~@/assets/imgs/par_14.jpg" alt=""></div> <!--天大-->
                    <div><img src="~@/assets/imgs/par_15.jpg" alt=""></div> <!--软银-->

                    <div><img src="~@/assets/imgs/par_21.jpg" alt=""></div> <!--株式会社-->
                    <div><img src="~@/assets/imgs/par_22.jpg" alt=""></div> <!--恒银金融-->
                    <div><img src="~@/assets/imgs/par_23.jpg" alt=""></div> <!--三甲-->
                    <div><img src="~@/assets/imgs/par_24.jpg" alt=""></div> <!--云译-->
                    <div><img src="~@/assets/imgs/par_25.jpg" alt=""></div> <!--中汽-->

                    <div><img src="~@/assets/imgs/par_31.jpg" alt=""></div> <!--塔米-->
                    <div><img src="~@/assets/imgs/par_32.jpg" alt=""></div> <!--INN-->
                    <div><img src="~@/assets/imgs/par_33.jpg" alt=""></div> <!--津云-->
                    <div><img src="~@/assets/imgs/par_34.jpg" alt=""></div> <!--烽火-->
                    <div><img src="~@/assets/imgs/par_35.jpg" alt=""></div> <!--天津广电-->
                </div>
            </div>
        </div>
        <div class="page-container-grey">
            <div class="page-hzzx">
                <p><span>点击下方按钮，获得我们的专属支持</span></p>
                <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
            </div>
        </div>
    </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
import Banner from './Banner.vue'
export default {
    name: 'sdk',
    components: {
        Banner
    },
    data () {
        return {
            playVideo1: true,
            playVideo2: true,
            playVideo3: true,
        }
    },
    mounted () {
        this.$nextTick(() => {
            new Swiper("#swiper2", {
                slidesPerView: 4,
                spaceBetween: 30,
                slidesPerGroup: 4,
                // loop: true,
                // loopFillGroupWithBlank: true,
                navigation: {
                    nextEl: "#swiper_prev2",
                    prevEl: "#swiper_prev2",
                }
            });
        })
    },
    methods: {
        playVideo (name) {
            if (name === 'video1') {
                if (this.$refs.video1.paused) {
                    this.$refs.video1.play()
                    this.playVideo1 = false
                } else {
                    this.$refs.video1.pause()
                    this.playVideo1 = true
                }
                this.$refs.video2.pause()
                this.$refs.video3.pause()
                this.playVideo2 = true
                this.playVideo3 = true
            }
            if (name === 'video2') {
                if (this.$refs.video2.paused) {
                    this.$refs.video2.play()
                    this.playVideo2 = false
                } else {
                    this.$refs.video2.pause()
                    this.playVideo2 = true
                }
                this.$refs.video1.pause()
                this.$refs.video3.pause()
                this.playVideo1 = true
                this.playVideo3 = true
            }
            if (name === 'video3') {
                if (this.$refs.video3.paused) {
                    this.$refs.video3.play()
                    this.playVideo3 = false
                } else {
                    this.$refs.video3.pause()
                    this.playVideo3 = true
                }
                this.$refs.video1.pause()
                this.$refs.video2.pause()
                this.playVideo1 = true
                this.playVideo2 = true
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import '../assets/css/product.css';
.hy_cpys_sdk_body{
    position: relative;
    width: 100%;
    height: 590px;
    background: url("../assets/imgs/sdk/dem_main.png") no-repeat center center;
    background-size: 100% 100%;
}
.video_pic_box{
    position: absolute;
    top: 60px;
    bottom: 15px;
    right: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    width: 256px;
}
.video_sdk_1,
.video_sdk_2,
.video_sdk_3{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 256px;
    height: 156px;
    cursor: pointer;
}
.video_sdk_1{
    background: url("../assets/imgs/sdk/dem_sb.jpg") no-repeat center center;
    background-size: 100% 100%;
}
.video_sdk_2{
    background: url("../assets/imgs/sdk/dem_hc.jpg") no-repeat center center;
    background-size: 100% 100%;
}
.video_sdk_3{
    background: url("../assets/imgs/sdk/dem_fy.jpg") no-repeat center center;
    background-size: 100% 100%;
}
.video_sdk_1 p,
.video_sdk_2 p,
.video_sdk_3 p{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.video_sdk_1 p:hover,
.video_sdk_2 p:hover,
.video_sdk_3 p:hover{
    background-color: rgba(0,0,0,0.5)!important;
}
.video_sdk_1 p span,
.video_sdk_2 p span,
.video_sdk_3 p span{
    display: block;
    font-size: 30px;
    color: #fff;
    text-align: center;
}
.s-slide-1 {
    background: url('~@/assets/imgs/sdk/case_img1.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-2 {
    background: url('~@/assets/imgs/sdk/case_img2.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-3 {
    background: url('~@/assets/imgs/sdk/case_img3.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-4 {
    background: url('~@/assets/imgs/sdk/case_img4.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>